import { ChevronForward, Ready, ShipFromDisplay } from 'easyship-components/icons'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Laptop from '@/components/icons/Laptop'
import { Container } from '@/ViewContainers/models/Container'

import { getContainerColorStatus, getPackageColorStatus } from '../utils'
import PackageCount from './PackageCount'

interface ContainerCardProps {
  container: Container
}

export default function ContainerCard({ container }: ContainerCardProps) {
  const navigate = useNavigate()

  function handleContainerClick() {
    navigate('/container-details', {
      state: { container: container },
    })
  }

  return (
    <div
      onClick={handleContainerClick}
      data-testid={`container-card-${container.id}`}
      className={twMerge(
        `${getContainerColorStatus(
          container.dwellingStatus,
        )} group mb-3 flex min-h-[66px] items-center justify-between rounded-md px-4 py-3 hover:cursor-pointer`,
      )}
    >
      <div className="flex w-full flex-wrap items-center justify-between">
        <div className="flex items-center">
          <div className="mr-2 min-w-[72px] text-2xl font-bold text-ink-900">
            {container.binAbbr}
          </div>
          <div className="flex w-[170px] items-center ">
            <div className="text-lg font-bold text-ink-900">{container.binName}</div>
            <div className="invisible group-hover:visible">
              <ChevronForward />
            </div>
          </div>
        </div>
        <div className="hidden items-center text-ink-500 md:flex font-bold">
          <ShipFromDisplay />
          {container.floorAreaName}
        </div>
        <div className="hidden items-center text-lg font-bold text-ink-500 md:flex">
          <Laptop />
          <div className="ml-1 font-bold">
            {container.stations?.join(', ')}
          </div>
        </div>
        <div
          className={twMerge(
            `flex min-w-[220px] max-w-[280px] items-center gap-2 rounded-full px-3 py-2 text-sm text-ink-500 ${getPackageColorStatus(
              container.dwellingStatus,
            )}`,
          )}
        >
          <div className="flex items-center text-ink-500 font-bold text-lg min-w-[26px] rounded-full">
            <Ready width={20} height={20} /> {container.totalPackages}
          </div>
          <div className="flex gap-2">
            <PackageCount statusKey="red" count={container.redPackages} />
            <PackageCount statusKey="orange" count={container.orangePackages} />
            <PackageCount statusKey="yellow" count={container.yellowPackages} />
            <PackageCount statusKey="white" count={container.whitePackages} />
          </div>
        </div>
      </div>
    </div>
  )
}
