import { ArrowTooltip, Chip } from 'easyship-components'
import { useNavigate } from 'react-router-dom'

import { CourierLogo } from '@/components/CourierLogo'
import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { NavigationStateParams as PackagePageNavigationStateParams } from '@/pages/PackagePage/PackagePage'
import { CourierUmbrella } from '@/ViewContainers/gateways/api/models/Container'
import { ContainerDetails } from '@/ViewContainers/models/Container'

import { getContainerColorStatus, getPackageSLAStatus, getSLAColorStatus } from '../utils'


interface PackageCardProps {
  pack: ContainerDetails['packages'][0]
}

export default function PackageCard({ pack }: PackageCardProps) {
  const {packagesGateway} = useGateways()
  const navigate = useNavigate()

  function handleCardClick() {
    packagesGateway.getPackageById(pack.id)
      .then((res) => {
        const navigationState: PackagePageNavigationStateParams = {
          package: res,
        }
        navigate('/package', { state: navigationState })
      })
      .catch(toastApiError)
  }

  return (
    <div
      onClick={handleCardClick}
      data-testid={`container-card-${pack.id}`}
      className={` ${getContainerColorStatus(
        pack.dwellingStatus,
      )}  group flex h-full min-h-[66px] flex-wrap items-center rounded-md px-4 py-3 hover:cursor-pointer md:block lg:flex `}
    >
      <div className="mr-3 flex w-1/3 flex-col md:mb-3">
        <div>
          <div className="text-sm text-ink-500">EVTN</div>
          <div className="text-xl font-bold text-ink-900">{pack.evtn}</div>
        </div>
        <div>
          <ArrowTooltip
            label={
              <div className="text-center">
                {getPackageSLAStatus(pack.dwellingStatus, pack.dwellingTime)}
              </div>
            }
            maxWidth="narrow"
            placement="top"
          >
            <div>
              <Chip rounded className={`${getSLAColorStatus(pack.dwellingStatus)} mt-2`}>
                {pack.dwellingTime}
              </Chip>
            </div>
          </ArrowTooltip>
        </div>
      </div>
      <div className="flex flex-col">
        {pack.firstMileTrackingNumber && (
          <div>
            <div className="text-sm text-ink-500">FMTN</div>
            <div className="text-lg font-bold text-ink-900">{pack.firstMileTrackingNumber}</div>
          </div>
        )}
        <div>
          <div className="text-sm text-ink-500">Easyship Shipment ID</div>
          <div className="flex items-center gap-2">
            <CourierLogo courierSlug={pack.courierUmbrellaName as CourierUmbrella} />
            <div className="text-lg font-bold text-ink-900">{pack.easyshipShipmentId}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
