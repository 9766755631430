import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { lazy, useState } from 'react'

import { useFeatureFlags } from '@/contexts/featureFlags'
import { useOpenModal } from '@/hooks/useOpenModal.ts'

import { usePackage } from './PackageProvider'

interface ActionItem {
  label: string
  isVisible: boolean
  callback: () => void
}

const PackageNotFoundConfirmationDialog = lazy(() => import('./PackageNotFoundConfirmationDialog'));

export default function PackageDropdownActions() {
  const {
    pack,
    printBarcode,
    reprintLabel,
    resetToMeasureStep,
    forceDestroyLiquidate,
    markAsReceivedDamaged,
  } = usePackage()

  const { isFeatureEnabled } = useFeatureFlags()
  const showMarkAsDamageForM4 =
    isFeatureEnabled('EX2_795_M4_repacking') && pack.canBeMarkedReceivedDamaged

  const isBinVisibilityEnabled = isFeatureEnabled('EX2_1444_bin_visibility_M1')
  const openModalHandler = useOpenModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const actions: ActionItem[] = [
    {
      label: 'Print Barcode',
      isVisible: pack.warehouseState.value !== 'not_received',
      callback: () => closeDropdownWithCallback(printBarcode),
    },
    {
      label: 'Print Shipping Label',
      isVisible: pack.labelState === 'generated',
      callback: () => closeDropdownWithCallback(reprintLabel),
    },
    {
      label: 'Re-measure',
      isVisible: pack.labelState === 'failed',
      callback: resetToMeasureStep,
    },
    {
      label: 'Mark as Received Damaged',
      isVisible:
        showMarkAsDamageForM4 &&
        (pack.warehouseState.value === 'received' ||
          pack.warehouseState.value === 'measured' ||
          pack.warehouseState.value === 'label_generated' ||
          pack.warehouseState.value === 'ready_for_xray' ||
          pack.warehouseState.value === 'xray_completed' ||
          pack.warehouseState.value === 'dg_inspection_required' ||
          pack.warehouseState.value === 'pg_inspection_required' ||
          pack.warehouseState.value === 'battery_inspection_required' ||
          pack.warehouseState.value === 'hvg_inspection_required'),
      callback: () => closeDropdownWithCallback(markAsReceivedDamaged.mutate),
    },
    {
      label: 'Ready for destroy/liquidate',
      isVisible:
        pack.warehouseState.value === 'label_failed' ||
        pack.warehouseState.value === 'dropped' ||
        pack.warehouseState.value === 'xray_completed',
      callback: forceDestroyLiquidate,
    },
    {
      label: 'Move to “Not Found”',
      isVisible: isBinVisibilityEnabled,
      callback: confirmMovePackageToNotFound,
    },
  ]
  const isDropdownEmpty = actions.every((action) => !action.isVisible)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function closeDropdownWithCallback(callback: () => void) {
    callback()
    setAnchorEl(null)
  }

  function confirmMovePackageToNotFound() {
    openModalHandler(PackageNotFoundConfirmationDialog, {
      packageId: pack.id,
    })
    setAnchorEl(null)
  }

  if (isDropdownEmpty) return null

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {actions.map(
          (action) =>
            action.isVisible && (
              <MenuItem className="!px-4 !py-5" key={action.label} onClick={action.callback}>
                {action.label}
              </MenuItem>
            ),
        )}
      </Menu>
    </>
  )
}
