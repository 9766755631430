import { FloorAreaName } from '@/const/floorAreaName'
import { DwellingStatus, PackageCountColor } from '@/ViewContainers/models/Container'

export const getContainerColorStatus = (status: DwellingStatus) => {
  switch (status) {
    case 'red':
      return `bg-red-100 shadow-[0px_2px_16px_rgba(40,78,102,0.10),0px_0px_8px_#DB3934]
              hover:shadow-[0px_0px_8px_#DB3934,0px_8px_20px_rgba(40,78,102,0.20)]`
    case 'orange':
      return `bg-[#FFE1CF] shadow-[0px_0px_8px_rgba(241,105,28,0.75),0px_8px_20px_rgba(40,78,102,0.20)]
              hover:shadow-[0px_0px_8px_rgba(241,105,28,0.75),0px_8px_20px_rgba(40,78,102,0.20)]`
    case 'yellow':
      return `bg-yellow-100 shadow-[0px_2px_16px_rgba(40,78,102,0.10)]
              hover:shadow-[0px_8px_20px_rgba(40,78,102,0.20)]`
    case 'green':
      return `bg-[#DCF6ED]
              hover:shadow-[0px_0px_8px_rgba(40, 78, 102, 0.2)]`
    case 'dark_grey':
      return `bg-sky-300 shadow-[0px_2px_16px_0px_rgba(40,78,102,0.10)]
              hover:shadow-[0px_8px_20px_rgba(40,78,102,0.20)]`
    default:
      return `bg-white shadow-[0px_2px_16px_rgba(40,78,102,0.10)]
              hover:shadow-[0px_8px_20px_rgba(40,78,102,0.20)]`
  }
}

export const getPackageColorStatus = (status: DwellingStatus) => {
  switch (status) {
    case 'red':
      return 'bg-red-300'
    case 'orange':
      return 'bg-[#FFBA93]'
    case 'yellow':
      return 'bg-yellow-300'
    case 'green':
      return 'bg-gree-900'
    case 'dark_grey':
      return 'bg-sky-500'
    default:
      return 'bg-sky-300'
  }
}

export const getSLAColorStatus = (status: DwellingStatus | PackageCountColor) => {
  switch (status) {
    case 'red':
      return 'bg-red-900'
    case 'orange':
      return 'bg-[#F1691C]'
    case 'yellow':
      return 'bg-yellow-900'
    case 'green':
      return 'bg-green-900'
    case 'white':
      return 'bg-[#FFFFFF] bg-opacity-60'
    case 'dark_grey':
      return 'bg-ink-300'
    default:
      return 'bg-sky-700'
  }
}

export const getPackageSLAStatus = (status: DwellingStatus, hour: string) => {
  switch (status) {
    case 'red':
      return `Package is breaching its max SLA time of ${hour} hours`
    case 'orange':
      return `Package is breaching its min SLA time of ${hour} hours`
    case 'yellow':
      return `Package is close to breaching its min SLA time of ${hour} hours`
    case 'green':
      return `Package has been Dropped`
    case 'dark_grey':
      return `Package has been moved to Not Found`
    case 'white':
      return `Package is currently on track for its SLA time`
    default:
      return ''
  }
}

export const getFloorAreaName = (floorAreaName: string) => {
  switch (floorAreaName) {
    case FloorAreaName.DROPPED:
      return 'Dropped'
    case FloorAreaName.NOT_FOUND:
      return 'Not Found'
    default:
      return floorAreaName
  }
}
