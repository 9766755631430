import { InfiniteData } from '@tanstack/react-query'
import { useState, useEffect, useContext, createContext, useMemo, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useDebounce from '@/hooks/useDebounce'
import useGetAvailableBinsTypeQuery from '@/ViewContainers/hooks/useGetAvailableBinsTypeQuery'
import { ContainersColorCounts } from '@/ViewContainers/models/Container'

import { useParams } from './ContainerDetailsPage/hooks/useParams'
import { ContainerFilterOptions } from './type/ContainerFilterOptions'
import { SLA_STATUS } from './type/SlaStatus'
import useGetAllContainersQuery, { BinPageData } from './useGetAllContainersQuery'
import useScanContainerQuery from './useScanContainerQuery'
import useSearchContainersQuery from './useSearchContainersQuery'

export type ToggleValues = 'SCAN' | 'SEARCH'

interface ViewItemsContextData {
  data: InfiniteData<BinPageData> | null
  isFetching: boolean
  containerFilterOptions: ContainerFilterOptions[]
  selectedContainerFilters: string[]
  dwellingStatus: string[]
  loadMoreRef: (node?: Element | null) => void
  handleFilterContainerType: (value: string) => void
  handleFilterDwellingStatus: (value: string) => void
  handleSearch: (searchTerm: string) => Promise<void>
  toggleValue: ToggleValues
  searchValue: string
  handleToggleSearchScan: (checked: boolean) => void
  handleSetSearchValue: (value: string) => void
  handleResetSearch: () => void
  showFilter: boolean
  handleResetFilter: () => void
  containerCounts: number
  handleScanBarcode: (barcode: string) => void
  slaStatusWithCounts: ContainerFilterOptions[]
}

interface ViewItemsProviderProps {
  children: React.ReactNode
}

export const ViewItemContext = createContext<ViewItemsContextData | undefined>(undefined)

function ViewItemsProvider({ children }: ViewItemsProviderProps) {
  const navigate = useNavigate()

  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedContainerFilters, setSelectedContainerFilters] = useState<string[]>(() => {
    const types = searchParams.get('selectedContainerFilters')
    return types ? types.split(',') : []
  })
  const [dwellingStatus, setDwellingStatus] = useState<string[]>(() => {
    const status = searchParams.get('dwellingStatus')
    return status ? status.split(',') : []
  })
  const [displayData, setDisplayData] = useState<InfiniteData<BinPageData> | null>(null)
  const { ref: loadMoreRef, inView } = useInView()
  const [binName, setBinName] = useState<string>(() => searchParams.get('binName') || '')
  const [showFilter, setShowFilter] = useState<boolean>(() => {
    return searchParams.get('showFilter') !== 'false'
  })
  const [toggleValue, setToggleValue] = useState<ToggleValues>(
    () => (searchParams.get('toggleValue') as ToggleValues) || 'SCAN',
  )
  const [searchValue, setSearchValue] = useState(() => searchParams.get('searchValue') || '')

  const { data: availableBinsType } = useGetAvailableBinsTypeQuery()

  const { data: scanData } = useScanContainerQuery(scanValueDebounced, toggleValue)

  const containerFilterOptions = useMemo(
    () => (availableBinsType ? [...availableBinsType.binTypes, ...availableBinsType.couriers] : []),
    [availableBinsType],
  )

  const {
    data: allContainersData,
    fetchNextPage: fetchNextPageAllContainers,
    hasNextPage,
    isFetching: isFetchingAll,
  } = useGetAllContainersQuery({ selectedContainerFilters, dwellingStatus })

  const { data: searchData, isFetching: isFetchingSearch } = useSearchContainersQuery({ binName })  
  const slaStatusCounts = allContainersData?.pages[0]?.containersColorCounts

  const slaStatusWithCounts = useMemo(() => {
    return SLA_STATUS.map((status) => ({
      ...status,
      count: slaStatusCounts?.[status.slug as keyof ContainersColorCounts] || 0,
    }))
  }, [slaStatusCounts])

  useEffect(() => {
    const newData = binName ? searchData : allContainersData
    if (newData) {
      setDisplayData(newData)
    }
  }, [binName, searchData, allContainersData])

  const isFetchingAny = isFetchingAll || isFetchingSearch

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPageAllContainers()
    }
  }, [inView, hasNextPage])

  const urlParamsConfig = useMemo(
    () => ({
      selectedContainerFilters,
      dwellingStatus,
      binName,
      showFilter,
      toggleValue,
      searchValue,
    }),
    [selectedContainerFilters, dwellingStatus, binName, showFilter, toggleValue, searchValue],
  )

  const { updateSearchParams } = useParams(urlParamsConfig)

  useEffect(() => {
    updateSearchParams()
  }, [updateSearchParams])

  const handleToggleSearchScan = useCallback((checked: boolean) => {
    setToggleValue(checked ? 'SEARCH' : 'SCAN')
  }, [])

  const handleSetSearchValue = useCallback((value: string) => {
    setSearchValue(value)
  }, [])

  const handleResetSearch = useCallback(() => {
    setSearchValue('')
    setBinName('')
    setShowFilter(true)
  }, [])

  const handleResetFilter = useCallback(() => {
    setSelectedContainerFilters([])
    setDwellingStatus([])
  }, [])

  function handleScanBarcode(newBarcode: string) {
    setScanValue(newBarcode)
  }

  useEffect(() => {
    if (toggleValue !== 'SCAN') {
      setScanValue('')
      return
    }

    if (scanData) {
      navigate('/container-details', {
        state: { container: scanData },
      })
      setScanValue('')
    }
  }, [scanData, toggleValue, navigate])

  async function handleSearch(searchTerm: string) {
    setBinName(searchTerm)
    setShowFilter(false)
  }

  const handleFilterContainerType = useCallback((value: string) => {
    setSelectedContainerFilters((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    )
  }, [])

  const handleFilterDwellingStatus = useCallback((value: string) => {
    setDwellingStatus((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    )
  }, [])

  const containerCounts = useMemo(() => {
    return Object.values(displayData?.pages[0].containersColorCounts || {}).reduce(
      (sum, count) => sum + count,
      0,
    )
  }, [displayData])

  const value = useMemo(
    () => ({
      data: displayData,
      slaStatusWithCounts,
      isFetching: isFetchingAny,
      containerFilterOptions,
      selectedContainerFilters,
      dwellingStatus,
      loadMoreRef,
      handleFilterContainerType,
      handleFilterDwellingStatus,
      handleSearch,
      toggleValue,
      searchValue,
      handleToggleSearchScan,
      handleSetSearchValue,
      handleResetSearch,
      showFilter,
      handleResetFilter,
      handleScanBarcode,
      containerCounts,
    }),
    [
      displayData,
      slaStatusWithCounts,
      isFetchingAny,
      containerFilterOptions,
      selectedContainerFilters,
      dwellingStatus,
      loadMoreRef,
      handleFilterContainerType,
      handleFilterDwellingStatus,
      handleSearch,
      toggleValue,
      searchValue,
      handleToggleSearchScan,
      handleSetSearchValue,
      handleResetSearch,
      showFilter,
      handleResetFilter,
      handleScanBarcode,
      containerCounts,
    ],
  )

  return <ViewItemContext.Provider value={value}>{children}</ViewItemContext.Provider>
}

function useViewItems() {
  const context = useContext(ViewItemContext)

  if (context === undefined) {
    throw new Error('useViewItems must be used within a ViewItemsProvider')
  }

  return context
}

export { ViewItemsProvider, useViewItems }
