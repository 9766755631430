import { useEffect, useState } from 'react'

import { PackageDetail, PackageStatus } from '@/ViewContainers/models/Container'

interface ContainerData {
  packageStatuses?: PackageStatus[]
  packageDetails?: PackageDetail[]
}

export function useOriginalFilterData(data: ContainerData | undefined) {
  const [originalPackageStatuses, setOriginalPackageStatuses] = useState<PackageStatus[]>([])
  const [originalPackageDetails, setOriginalPackageDetails] = useState<PackageDetail[]>([])

  useEffect(() => {
    if (data?.packageStatuses && originalPackageStatuses.length === 0) {
      setOriginalPackageStatuses(data.packageStatuses)
    }
    if (data?.packageDetails && originalPackageDetails.length === 0) {
      setOriginalPackageDetails(data.packageDetails)
    }
  }, [data?.packageStatuses, data?.packageDetails])

  return {
    originalPackageStatuses,
    originalPackageDetails,
  }
}
