import NoPackagesFoundSvg from '../../../assets/no_packages.svg'

export default function NoPackagesFound() {
  return (
    <div className="flex flex-col items-center justify-center gap-5 mt-10">
      <img
        src={NoPackagesFoundSvg}
        style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
      />
      <div className="text-2xl font-bold">No packages</div>
      <div>There are no packages in this container.</div>
    </div>
  )
}
