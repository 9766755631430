import { Container } from '@mui/material'
import { Alert, Chip, Pulse } from 'easyship-components'
import { Ready, ShipFromDisplay, Container as ContainerIcon } from 'easyship-components/icons'
import { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Laptop from '@/components/icons/Laptop'
import { Container as ContainerType, DwellingStatus } from '@/ViewContainers/models/Container'

import ActionsButton from '../ActionsButton'
import Filter from '../components/Filter'
import NoPackagesFound from '../components/NoPackagesFound'
import PackageCard from '../components/PackageCard'
import { ContainerFilterOptions } from '../type/ContainerFilterOptions'
import { SLA_STATUS } from '../type/SlaStatus'
import useGetContainerDetailsQuery from '../useGetContainerDetailsQuery'
import { getFloorAreaName, getSLAColorStatus } from '../utils'
import { ContainerDetailsAppBar } from './ContainerDetailsAppBar'
import { useOriginalFilterData } from './hooks/useOriginalFilterData'
import { useUpdateFilterUrlParams } from './hooks/useUpdateFilterUrlParams'

export type NavigationStateParams =
  | Partial<{
      container: ContainerType
      originalParams?: string
    }>
  | undefined

const slugToPackageKeyMap: Record<string, string> = {
  red: 'redPackages',
  white: 'whitePackages',
  orange: 'orangePackages',
  yellow: 'yellowPackages',
}

export default function ContainerDetailsPage() {
  const { state } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const binId = searchParams.get('binId')

  // Initialize filter states from URL parameters
  const [packStatus, setPackStatus] = useState<string[]>(() => {
    const selectedContainerFilters = searchParams.get('selectedContainerFilters')
    return selectedContainerFilters ? selectedContainerFilters.split(',') : []
  })
  const [packDetails, setPackDetails] = useState<string[]>(() => {
    const details = searchParams.get('packageDetails')
    return details ? details.split(',') : []
  })
  const [dwellingStatus, setDwellingStatus] = useState<string[]>(() => {
    const status = searchParams.get('dwellingStatus')
    return status ? status.split(',') : []
  })

  const navigationState = state as NavigationStateParams
  const container = navigationState?.container
  const { data, isFetching } = useGetContainerDetailsQuery({
    containerId: binId || (navigationState?.container?.id as string),
    warehouseState: packStatus,
    dwellingStatus,
    packageDetail: packDetails,
  })

  const isNonMovableContainer = !!data?.stationary

  const { originalPackageStatuses, originalPackageDetails } = useOriginalFilterData(data)
  useUpdateFilterUrlParams({ packStatus, packDetails, dwellingStatus })

  const [initialSlaStatusCounts, setInitialSlaStatusCounts] = useState<ContainerFilterOptions[]>([])

  useEffect(() => {
    if (data && initialSlaStatusCounts.length === 0) {
      const calculatedCounts = SLA_STATUS.map((status) => {
        const packageKey = slugToPackageKeyMap[status.slug]
        const count = packageKey ? data[packageKey as keyof typeof data] ?? 0 : 0
        return {
          ...status,
          count: count as number,
        }
      })
      setInitialSlaStatusCounts(calculatedCounts)
    }
  }, [data, initialSlaStatusCounts])

  // Get the original URL parameters from navigation state or current URL
  const originalParams = useMemo(() => {
    if (navigationState?.originalParams) {
      return new URLSearchParams(navigationState.originalParams)
    }
    // If no original params in state, get them from the current URL
    const params = new URLSearchParams(window.location.search)
    // Store the original toggleValue and selectedContainerFilters if they exist
    const toggleValue = params.get('toggleValue')
    const selectedContainerFilters = params.get('selectedContainerFilters')
    if (toggleValue) params.set('toggleValue', toggleValue)
    if (selectedContainerFilters) params.set('selectedContainerFilters', selectedContainerFilters)
    return params
  }, [navigationState])

  return (
    <>
      <ContainerDetailsAppBar navigate={navigate} originalParams={originalParams} />
      {isFetching || !data ? (
        <>
          <Container sx={{ my: 3 }}>
            <Pulse className="relative mt-4 flex justify-center" show />
          </Container>
        </>
      ) : (
        <>
          <Container sx={{ my: 3 }}>
            <div className="mb-8 flex justify-between">
              <div className="flex items-center gap-2">
                <ContainerIcon />
                <div className="text-2xl font-bold text-ink-900">{data?.binName}</div>
              </div>
              {!isNonMovableContainer && <ActionsButton />}
            </div>
            <div className="flex flex-col gap-3 rounded-lg bg-white p-4 shadow">
              <div className="flex w-full flex-col items-start justify-between gap-3 sm:flex-row sm:items-center sm:gap-0">
                <div>
                  <div className="mb-1 text-sm text-ink-500">Location</div>
                  <div className="flex items-center text-lg font-bold text-ink-900">
                    <ShipFromDisplay />
                    {getFloorAreaName(data?.floorAreaName)}
                  </div>
                </div>
                <div>
                  <div className="mb-1 text-sm text-ink-500">Station(s)</div>
                  <div className="flex gap-2 text-lg font-bold text-ink-900">
                    <Laptop />
                    {data?.stations?.join(', ')}
                  </div>
                </div>
                <div>
                  <div className="mb-1 text-sm text-ink-500">Packages</div>
                  <div className="flex items-center text-lg font-bold text-ink-900">
                    <Ready width={20} height={20} /> {data?.totalPackages}
                  </div>
                </div>
                <div>
                  <div className="mb-1 text-sm text-ink-500">Longest dwelling time</div>
                  <div>
                    <Chip
                      className={twMerge(
                        getSLAColorStatus(container?.dwellingStatus as DwellingStatus),
                        'mt-2 rounded-[10px]',
                      )}
                    >
                      {data?.longestDwellingTime}
                    </Chip>
                  </div>
                </div>
                <div>
                  <div className="mb-1 text-sm text-ink-500">Total Weight</div>
                  <div className="text-lg font-bold text-ink-900">{data?.totalWeight}</div>
                </div>
              </div>
              {isNonMovableContainer && (
                <Alert severity="warning" className="p-3">
                  This is the non-moveable &quot;container&quot; for Station{' '}
                  {data?.stations?.join(', ')}
                </Alert>
              )}
            </div>
            <div className="mb-4 mt-8 text-lg text-ink-900">
              All Packages <span className="font-bold"> ({data?.totalPackages})</span>
            </div>
            <div className="hidden sm:block">
              <div>
                <Filter
                  filterOptions={originalPackageStatuses}
                  isDesktop
                  label="Package status"
                  selectedFilters={packStatus}
                  onFilterClick={(value) => {
                    setPackStatus((prev) =>
                      prev.includes(value)
                        ? prev.filter((prevValue) => prevValue !== value)
                        : [...prev, value],
                    )
                  }}
                />
              </div>
              <Filter
                filterOptions={originalPackageDetails}
                isDesktop
                label="Package details"
                selectedFilters={packDetails}
                onFilterClick={(value) => {
                  setPackDetails((prev) =>
                    prev.includes(value)
                      ? prev.filter((prevValue) => prevValue !== value)
                      : [...prev, value],
                  )
                }}
              />
              <Filter
                filterOptions={initialSlaStatusCounts}
                isDesktop
                label="SLA status"
                showCounts={true}
                selectedFilters={dwellingStatus}
                onFilterClick={(value) => {
                  setDwellingStatus((prev) =>
                    prev.includes(value)
                      ? prev.filter((prevValue) => prevValue !== value)
                      : [...prev, value],
                  )
                }}
              />
            </div>
            {!data?.packages?.length ? (
              <NoPackagesFound />
            ) : (
              <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
                {data?.packages.map((pack, index) => {
                  return <PackageCard pack={pack} key={index} />
                })}
                {isFetching && <Pulse className="relative mt-4 flex justify-center" show />}
              </div>
            )}
          </Container>
        </>
      )}
    </>
  )
}
