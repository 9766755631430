import { CourierUmbrella } from '@/ViewContainers/gateways/api/models/Container'

// Note: Remap courier name to image name from https://console.cloud.google.com/storage/browser/easyship-assets/app/courier-logos
export function getCourierLogoUrl(courierSlug: CourierUmbrella) {
  switch (courierSlug) {
    case CourierUmbrella.Asendia:
      return 'asendia'
    case CourierUmbrella.PitneyBowes:
      return 'pitney-bowes'
    case CourierUmbrella.DHLeCommerce:
      return 'dhl-ecommerce'
    case CourierUmbrella.DHL:
      return 'dhl'
    case CourierUmbrella.EPostGlobal:
      return 'epost-global'
    case CourierUmbrella.SFExpress:
      return 'sf-express'
    case CourierUmbrella.SkyPostal:
      return 'skypostal'
  }
}
