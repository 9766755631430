import { twMerge } from 'tailwind-merge'

import { getCourierLogoUrl } from '@/utils/courierLogo'
import { CourierUmbrella } from '@/ViewContainers/gateways/api/models/Container'

interface CourierLogoProps {
  courierSlug: CourierUmbrella | null
  className?: string
}

export function CourierLogo({ courierSlug, className }: CourierLogoProps) {
  if (!courierSlug) {
    return null
  }

  return (
    <img
      src={`https://assets.easyship.com/app/courier-logos/${getCourierLogoUrl(
        courierSlug,
      )}-mini.svg`}
      alt={courierSlug}
      className={twMerge('h-4 w-5', className)}
    />
  )
}
