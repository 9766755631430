import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type SearchParamValue = string | string[] | boolean | null

interface SearchParamsConfig {
  selectedContainerFilters: string[]
  dwellingStatus: string[]
  binName: string
  showFilter: boolean
  toggleValue: string
  searchValue: string
}

export function useParams(config: SearchParamsConfig) {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParams = useCallback(() => {
    const params = new URLSearchParams(searchParams)

    const updateParam = (key: string, value: SearchParamValue) => {
      if (Array.isArray(value)) {
        if (value.length) {
          params.set(key, value.join(','))
        } else {
          params.delete(key)
        }
      } else if (value !== null && value !== undefined) {
        if (typeof value === 'boolean' && !value) {
          params.set(key, 'false')
        } else {
          params.set(key, String(value))
        }
      } else {
        params.delete(key)
      }
    }

    Object.entries(config).forEach(([key, value]) => {
      updateParam(key, value)
    })

    setSearchParams(params, { replace: true })
  }, [config, searchParams, setSearchParams])

  return {
    updateSearchParams,
    searchParams,
  }
}
