import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

interface ContainerQueryParams {
  containerId: string
  warehouseState: string[]
  dwellingStatus: string[]
  packageDetail: string[]
}

export default function useGetContainerDetailsQuery({
  containerId,
  warehouseState,
  dwellingStatus,
  packageDetail,
}: ContainerQueryParams) {
  const { binsGateway } = useGateways()

  const query = useQuery({
    queryKey: ['containerDetails', containerId, warehouseState, dwellingStatus, packageDetail],
    queryFn: () =>
      binsGateway.getContainerDetails(containerId, warehouseState, dwellingStatus, packageDetail),
    enabled: !!containerId,
  })

  return query
}
