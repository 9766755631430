import { useInfiniteQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

import { BinPageData } from './type/BinPageData'

interface ContainerQueryParams {
  selectedContainerFilters: string[]
  dwellingStatus: string[]
}

export default function useGetAllContainersQuery({
  selectedContainerFilters,
  dwellingStatus,
}: ContainerQueryParams) {
  const { binsGateway } = useGateways()

  const query = useInfiniteQuery<BinPageData>({
    queryKey: ['allContainers', selectedContainerFilters, dwellingStatus],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await binsGateway.getAllContainers(
        selectedContainerFilters,
        dwellingStatus,
        pageParam,
      )
      return data
    },
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.pagination.next
      return nextPage === null ? undefined : nextPage
    },
  })

  return query
}

export type { BinPageData }
