import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function Laptop(props: SvgIconProps) {
  return (
    <SvgIcon {...props} sx={{ width: 16 }} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9059 2.8001H4.09414C3.87323 2.8001 3.69414 2.97918 3.69414 3.2001V8.02363H12.3059V3.2001C12.3059 2.97918 12.1268 2.8001 11.9059 2.8001ZM4.09414 1.6001C3.21048 1.6001 2.49414 2.31644 2.49414 3.2001V9.22363H13.5059V3.2001C13.5059 2.31644 12.7896 1.6001 11.9059 1.6001H4.09414Z"
        fill="#454F5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800049 12.843L0.800059 12.8472C0.802348 13.3131 1.18071 13.69 1.64711 13.69H14.353C14.8194 13.69 15.1978 13.3131 15.2 12.8472V12.843H15.1979L13.5059 9.45898H2.49417L0.802174 12.843H0.800049ZM5.98944 12.0566C5.93799 12.1877 6.03472 12.3296 6.17563 12.3296H9.82441C9.96532 12.3296 10.062 12.1877 10.0106 12.0566L9.66919 11.186C9.63916 11.1094 9.56528 11.059 9.483 11.059H6.51704C6.43476 11.059 6.36088 11.1094 6.33085 11.186L5.98944 12.0566Z"
        fill="#454F5B"
      />
    </SvgIcon>
  )
}
