import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import IconCheck from '../../../../assets/Icon-check.svg'
import IconCross from '../../../../assets/Icon-cross.svg'
import BackButton from '../BackButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const MatchDescription = () => {
  const { setCurrentStep, setInspectionAnswers } = useInspectionQuestions()

  function handleBackClick() {
    setInspectionAnswers((prevState) => ({ ...prevState, doesItemDescriptionMatch: null }))
    setCurrentStep(InspectionSteps.PACKAGE_EMPTY)
  }
  return (
    <Stack alignItems="center" spacing={2}>
      <h1>Items match description?</h1>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          aria-label="does not match description"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={() => {
            setInspectionAnswers((prevState) => ({
              ...prevState,
              doesItemDescriptionMatch: false,
            }))
            setCurrentStep(InspectionSteps.CONFIRM_INCORRECT_ITEM)
          }}
        >
          <img
            src={IconCross}
            height={36}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />

          <Typography variant="h1">no</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          aria-label="does match description"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={() => {
            setInspectionAnswers((prevState) => ({ ...prevState, doesItemDescriptionMatch: true }))
            setCurrentStep(InspectionSteps.QUANTITY_MATCH)
          }}
        >
          <img
            src={IconCheck}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />
          <Typography variant="h1">yes</Typography>
        </Button>
      </Stack>
      <Stack alignItems="flex-start" width="100%">
        <BackButton onBackClick={handleBackClick} />
      </Stack>
    </Stack>
  )
}
