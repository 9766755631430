import {  Pulse } from 'easyship-components'
import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Container } from '@/ViewContainers/models/Container'

import ContainerCard from './components/ContainerCard'
import NoContainersFound from './components/NoContainersFound'
import { useViewItems } from './ViewItemsProvider'

export type NavigationStateParams =
  | Partial<{
      container: Container
      originalParams?: string
    }>
  | undefined

export default function AllContainersList() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data, isFetching, loadMoreRef } = useViewItems()
  const noMatchContainersFound = data?.pages[0]?.containers.length === 0

  const handleContainerClick = useCallback(
    (container: Container) => {
      navigate('/container-details', {
        state: {
          container,
          originalParams: searchParams.toString(),
        },
      })
    },
    [navigate, searchParams],
  )

  return (
    <>
      {noMatchContainersFound ? (
        <NoContainersFound/>
      ) : (
        <>
          {data?.pages.map((page, pageIndex) => (
            <div key={pageIndex} className="space-y-4">
              {page.containers.map((container) => (
                <div ref={loadMoreRef} key={container.id} onClick={() => handleContainerClick(container)}>
                  <ContainerCard container={container} />
                </div>
              ))}
            </div>
          ))}
        </>
      )}

      {isFetching && (
        <div className="flex justify-center py-4">
          <Pulse show />
        </div>
      )}
    </>
  )
}
