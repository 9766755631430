import { useInfiniteQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

interface SearchContainersQueryParams {
  binName: string
  page?: number
  items?: number
  pageParam?: number
}

export default function useSearchContainersQuery({
  binName,
  page = 1,
  items = 20,
}: SearchContainersQueryParams) {
  const { binsGateway } = useGateways()

  const query = useInfiniteQuery({
    queryKey: ['searchContainers', binName],
    queryFn: ({ pageParam = 1 }) => binsGateway.getSearchContainers(binName, pageParam, items),
    getNextPageParam: (lastPage) => lastPage.meta.pagination.next,
    enabled: !!binName,
  })

  return query
}
