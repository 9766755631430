import axios, { AxiosResponse } from 'axios'

import FloorLocation from '@/core/models/FloorLocation'
import { RemovePackagesResult } from '@/core/models/Package'
import { MoveBinFormData } from '@/ViewContainers/BinDetailsPage/MoveBinDialog'
import { CreateMultipleBins, ProcessStage } from '@/ViewContainers/models/Bin'
import BinBatch from '@/ViewContainers/models/BinBatch'
import BinDetails from '@/ViewContainers/models/BinDetails'
import BinType from '@/ViewContainers/models/BinType'
import { ContainerDetails, Containers } from '@/ViewContainers/models/Container'
import DeletePrintQueue from '@/ViewContainers/models/DeletePrintQueue'
import { PrintedQueue } from '@/ViewContainers/models/PrintedQueue'
import PrintQueue from '@/ViewContainers/models/PrintQueue'
import BinsGateway from '@/ViewContainers/ports/bins'

import {
  BatchMarkForInspectionPayload,
  InspectionSource,
  PartialSuccessPackagesResponse,
} from '../../../gateways/api/models/Package'
import { mapAvailableBinsTypeFromApi } from './mappers/availableBinsTypeMapper'
import { mapFromApi as mapBinDetailsFromApi } from './mappers/binDetailsMapper'
import { mapFromApi as mapBinFromApi } from './mappers/BinMapper'
import { mapAllContainers, mapContainerDetails } from './mappers/containerMapper'
import { mapFromApi as mapPrintedQueue } from './mappers/PrintedQueueMapper'
import { mapPrintQueueFromApi } from './mappers/printQueueMapper'
import {
  BinDetailsResponse,
  BinMovePayload,
  BinTypesResponse,
  FloorLocationsResponse,
  RemovePackagesPayload,
  ProcessStagesResponse,
} from './models/Bin'
import { ContainerDetailsResponse, ContainersResponse } from './models/Container'
import { PrintQueueResponse } from './models/PrintQueue'

function getBinFloorLocations(): Promise<FloorLocation[]> {
  return axios
    .get<FloorLocationsResponse>('/v1/bins/properties/floor_areas')
    .then(({ data }) => data.floor_areas.sort((a, b) => a.name.localeCompare(b.name)))
}

function getBinDetailsByBarcode(barcode: string): Promise<BinDetails> {
  return axios
    .get<BinDetailsResponse>(`/v1/bins/${barcode}/details`)
    .then(({ data }) => mapBinDetailsFromApi(data.bin))
}

function moveBin(binId: number, formData: MoveBinFormData): Promise<void> {
  return axios.patch<void, void, BinMovePayload>(`/v1/bins/${binId}/floor_area`, {
    floor_area: { id: formData.floorLocationId },
  })
}

function printBarcode(binId: number): Promise<void> {
  return axios.post(`/v1/bins/${binId}/print_barcode`)
}

function getBinProcessStages(): Promise<ProcessStage[]> {
  return axios.get<ProcessStagesResponse>('/v1/bins/process_stages').then(({ data }) =>
    data.process_stages.map((processStage) => ({
      name: processStage.name,
      bins: processStage.bins.map((bin) => mapBinFromApi(bin)),
    })),
  )
}

function removePackages(binId: number, barcodes: string[]): Promise<RemovePackagesResult> {
  return axios
    .post<
      PartialSuccessPackagesResponse,
      AxiosResponse<PartialSuccessPackagesResponse>,
      RemovePackagesPayload
    >(`/v1/bins/${binId}/remove_packages`, { barcodes })
    .then(({ data }) => ({
      succeededPackageIds: data.succeeded_packages.map((pack) => pack.id),
      failedBarcodes: data.error?.failed_barcodes ?? [],
      errorMessage: data.error?.message ?? '',
    }))
}

async function markForInspection(
  binId: number,
  barcodes: string[],
  source: InspectionSource,
): Promise<void> {
  await axios.post<
    PartialSuccessPackagesResponse,
    AxiosResponse<PartialSuccessPackagesResponse>,
    BatchMarkForInspectionPayload
  >(`/v1/bins/${binId}/request_inspection`, {
    last_mile_tracking_numbers: barcodes,
    inspection_source: source,
  })
}

function markXRayAsCompleted(binId: number): Promise<void> {
  return axios.post(`/v1/bins/${binId}/mark_as_xray_completed`)
}

function getPrintQueue(): Promise<PrintQueue[]> {
  return axios
    .get<PrintQueueResponse>('/v1/print_queues')
    .then(({ data }) => mapPrintQueueFromApi(data.print_queues))
}

function createBinsBatch(bins: CreateMultipleBins[]): Promise<BinBatch> {
  return axios.post('/v1/batch/bins', {
    bins: bins.map((bin) => ({
      bin_type_slug: bin.binTypeSlug,
      courier_slug: bin.courierSlug,
      quantity: bin.quantity,
    })),
  })
}

function getAvailableBinsTypes(): Promise<BinType> {
  return axios
    .get<BinTypesResponse>('/v1/bins/properties')
    .then(({ data }) => mapAvailableBinsTypeFromApi(data))
}

function deletePrintQueue(queueId: number): Promise<DeletePrintQueue> {
  return axios.delete(`/v1/print_queues/${queueId}`)
}

function printQueue(queueId: number): Promise<PrintedQueue> {
  return axios
    .post(`/v1/print_queues/${queueId}/print`)
    .then(({ data }) => mapPrintedQueue(data.print_queue))
}

function RetryPrintItem(queueId: number, queueItemId: number): Promise<void> {
  return axios.post(`/v1/print_queues/${queueId}/print_queue_items/${queueItemId}/retry`)
}

function getAllContainers(
  setSelectedContainerFilters: string[],
  dwellingStatus: string[],
  pageParam: number,
): Promise<Containers> {
  return axios
    .get<ContainersResponse>('/v1/containers', {
      params: {
        bin_type_slug: setSelectedContainerFilters,
        dwelling_status: dwellingStatus,
        page: pageParam,
        items: 20,
      },
    })
    .then(({ data }) => mapAllContainers(data))
}

function getContainerDetails(
  id: string,
  warehouseState: string[],
  dwellingStatus: string[],
  packageDetail: string[],
): Promise<ContainerDetails> {
  return axios
    .get<ContainerDetailsResponse>(`/v1/containers/${id}`, {
      params: {
        package_warehouse_state: warehouseState,
        package_detail: packageDetail,
        dwelling_status: dwellingStatus,
      },
    })
    .then(({ data }) => mapContainerDetails(data))
}

function getSearchContainers(binName: string, limit: number): Promise<Containers> {
  return axios
    .get<ContainersResponse>(`/v1/containers/search`, {
      params: { bin_name: binName, limit, items: 20 },
    })
    .then(({ data }) => mapAllContainers(data))
}

function getScanContainer(barcode: string): Promise<ContainerDetails> {
  return axios
    .get<ContainerDetailsResponse>(`/v1/containers/scan/${barcode}`)
    .then(({ data }) => mapContainerDetails(data))
}

function moveToNotFound(binId: string): Promise<void> {
  return axios.post(`/v1/bins/${binId}/mark_as_not_found`)
}

export default function createApiBinsGateway(): BinsGateway {
  return {
    getBinFloorLocations,
    getBinDetailsByBarcode,
    moveBin,
    printBarcode,
    getBinProcessStages,
    removePackages,
    markForInspection,
    markXRayAsCompleted,
    getPrintQueue,
    getAvailableBinsTypes,
    createBinsBatch,
    deletePrintQueue,
    printQueue,
    RetryPrintItem,
    getAllContainers,
    getContainerDetails,
    getSearchContainers,
    getScanContainer,
    moveToNotFound,
  }
}
