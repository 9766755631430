import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Alert, Box, Snackbar } from '@mui/material'
import Button from '@mui/material/Button'
import MuiCollapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Spacer from '@/components/Spacer'
import Switch from '@/components/Switch'
import { toastApiError } from '@/components/Toastify'
import Package from '@/core/models/Package'
import useOrders from '@/core/useOrders'
import usePackages from '@/core/usePackages'
import useDebounce from '@/hooks/useDebounce'
import { NavigationStateParams as PackagePageNavigationStateParams } from '@/pages/PackagePage/PackagePage'
import { NavigationStateParams as PackagesSelectionPageNavigationStateParams } from '@/pages/PackagesSelectionPage/PackagesSelectionPage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'
import { NavigationStateParams as ReturnPackagePageNavigationStateParams } from '@/returnPackage/ReturnPackagePage'
import { NavigationStateParams as SearchResultPageNavigationStateParams } from '@/searchResult/SearchResultPage'

type ToggleValues = 'SCAN' | 'SEARCH'

export interface SearchFormData {
  evtn: string
  sellerFirstName: string
  sellerLastName: string
  buyerFirstName: string
  buyerLastName: string
}

export const defaultSearchFormData: SearchFormData = {
  evtn: '',
  buyerFirstName: '',
  buyerLastName: '',
  sellerFirstName: '',
  sellerLastName: '',
}

interface SearchPackageViewProps {
  onSubmit: (formData: SearchFormData) => void
}

function SearchPackageView({ onSubmit }: SearchPackageViewProps) {
  const [formData, setFormData] = useState<SearchFormData>(defaultSearchFormData)
  const [open, setOpen] = useState(false)
  const isSearchEmpty = Object.values(formData).every((value) => value.length === 0)

  function searchPackage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSubmit(formData)
  }

  function resetSearchValues() {
    setFormData(defaultSearchFormData)
  }

  function updateSearchValues(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.currentTarget
    const updatedValue = { [name]: value }
    setFormData((prevState) => ({ ...prevState, ...updatedValue }))
  }

  return (
    <form onSubmit={searchPackage}>
      <TextField
        inputProps={{ 'data-hj-allow': true }}
        placeholder="EVTN"
        name="evtn"
        onChange={updateSearchValues}
        value={formData.evtn}
        fullWidth
        autoFocus
      />
      <Stack>
        <MuiCollapse in={open}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              inputProps={{ 'data-hj-allow': true }}
              name="sellerFirstName"
              placeholder="Seller First Name"
              onChange={updateSearchValues}
              value={formData.sellerFirstName}
              fullWidth
            />
            <TextField
              inputProps={{ 'data-hj-allow': true }}
              name="sellerLastName"
              placeholder="Seller Last Name"
              onChange={updateSearchValues}
              value={formData.sellerLastName}
              fullWidth
            />
            <TextField
              inputProps={{ 'data-hj-allow': true }}
              name="buyerFirstName"
              placeholder="Buyer First Name"
              onChange={updateSearchValues}
              value={formData.buyerFirstName}
              fullWidth
            />
            <TextField
              inputProps={{ 'data-hj-allow': true }}
              name="buyerLastName"
              placeholder="Buyer Last Name"
              onChange={updateSearchValues}
              value={formData.buyerLastName}
              fullWidth
            />
          </Stack>
        </MuiCollapse>
        <Button variant="text" onClick={() => setOpen(!open)} sx={{ my: 2 }}>
          {open ? 'Hide' : 'More'}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ height: 40 }}
          onClick={resetSearchValues}
        >
          Reset
        </Button>
        <Button disabled={isSearchEmpty} variant="contained" sx={{ height: 40 }} type="submit">
          Search
        </Button>
      </Box>
    </form>
  )
}

export default function ScanPage() {
  const navigate = useNavigate()
  const { scan } = usePackages()
  const { search } = useOrders()
  const [toggleValue, setToggleValue] = useState<ToggleValues>('SCAN')
  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)
  const [scanNoPackagesFoundError, setScanNoPackagesFoundError] = useState(false)

  useEffect(() => {
    if (scanValueDebounced) {
      setScanNoPackagesFoundError(false)
      scan(scanValueDebounced).then(handlePackagesResponse).catch(toastApiError)
    }
  }, [scanValueDebounced])

  function toggleSearchScan(checked: boolean) {
    setToggleValue(checked ? 'SEARCH' : 'SCAN')
    setScanNoPackagesFoundError(false)
  }

  async function handleSearch(formData: SearchFormData) {
    try {
      const orders = await search(formData)
      if (orders.length === 0) {
        setScanNoPackagesFoundError(true)
        setScanValue('')
        return
      }
      const navigationState: SearchResultPageNavigationStateParams = {
        searchParams: formData,
        orders,
      }

      navigate('/search-result', { state: navigationState })
    } catch (reason) {
      toastApiError(reason)
    }
  }

  function handlePackagesResponse(
    packages: (Package | ReturnPackage)[],
    searchParams?: SearchFormData,
  ) {
    function isReturnPackage(pack: Package | ReturnPackage): pack is ReturnPackage {
      return !!(pack as ReturnPackage).outboundEvtn
    }

    function isPackageList(packages: (Package | ReturnPackage)[]): packages is Package[] {
      return packages.some((pack) => !(pack as ReturnPackage).outboundEvtn)
    }
    if (packages.length === 0) {
      setScanNoPackagesFoundError(true)
      setScanValue('')
    } else if (packages.length === 1) {
      const pack = packages[0]
      if (isReturnPackage(pack)) routeToReturnPackagePage(pack)
      else routeToPackagePage(pack, searchParams)
    } else {
      if (isPackageList(packages)) routeToPackagesSelectionPage(packages, searchParams)
    }
  }

  function routeToReturnPackagePage(pack: ReturnPackage) {
    const navigationState: ReturnPackagePageNavigationStateParams = { package: pack }

    navigate('/return-package', { state: navigationState })
  }

  function routeToPackagePage(pack: Package, searchParams?: SearchFormData) {
    const navigationState: PackagePageNavigationStateParams = {
      package: pack,
      searchParams,
    }

    navigate('/package', { state: navigationState })
  }

  function routeToPackagesSelectionPage(packages: Package[], searchParams?: SearchFormData) {
    const navigationState: PackagesSelectionPageNavigationStateParams = {
      packages,
      searchParams,
    }

    navigate('/packages-selection', { state: navigationState })
  }

  return (
    <>
      <AppBar>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack spacing={2}>
          <Switch
            leftLabel="Scan"
            rightLabel="Search"
            checked={toggleValue === 'SEARCH'}
            onChange={(_, checked) => toggleSearchScan(checked)}
          />
          {toggleValue === 'SCAN' && (
            <>
              <TextField
                inputProps={{ 'data-hj-allow': true }}
                placeholder="9400 1000 0000 0000 0000 00"
                helperText="Can't scan? Use the Search function."
                onChange={(e) => setScanValue(e.currentTarget.value)}
                value={scanValue}
                fullWidth
                autoFocus
              />
            </>
          )}
          {toggleValue === 'SEARCH' && <SearchPackageView onSubmit={handleSearch} />}
        </Stack>
      </Container>
      <Snackbar
        open={scanNoPackagesFoundError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setScanNoPackagesFoundError(false)}
      >
        <Alert severity="warning">No Packages Found</Alert>
      </Snackbar>
    </>
  )
}
