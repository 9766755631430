import Link from '@mui/material/Link'
import { ArrowTooltip, Chip } from 'easyship-components'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { PackageLocationAndSla } from '@/core/models/Package'
import { DwellingStatus } from '@/ViewContainers/models/Container'
import {  getSLAColorStatus } from '@/ViewItems/utils'

import { gePackageTooltipMessage } from './SlaStatus'

interface LocationProps {
  packageLocationAndSla: PackageLocationAndSla | null
}

export default function Location({ packageLocationAndSla }: LocationProps) {
  const navigate = useNavigate()

  const handleContainerClick = () => {
    const url = `/container-details${packageLocationAndSla?.binId ? `?binId=${packageLocationAndSla.binId}` : ''}`;
    navigate(url);
  };

  if(!packageLocationAndSla) return null

  return (
    <div className="flex gap-2 items-center whitespace-nowrap">
      <Link className="inline" target="_blank">
        {packageLocationAndSla.floorAreaName}
      </Link>
      { packageLocationAndSla && packageLocationAndSla.binName && (
        <>
          <span>{`>`}</span>
          <Link className="inline hover:cursor-pointer" target="_blank" onClick={handleContainerClick}>
            {packageLocationAndSla.binName}
          </Link>
          <ArrowTooltip
            maxWidth="default"
            placement="top"
            disabled={!packageLocationAndSla}
            label={
              <div className={twMerge("text-center")}>
                {gePackageTooltipMessage(
                  packageLocationAndSla?.dwellingStatus as DwellingStatus,
                  packageLocationAndSla?.dwellingTime,
                )}
              </div>
            }
          >
            <div>
              <Chip
                className={twMerge(
                  getSLAColorStatus(packageLocationAndSla?.dwellingStatus as DwellingStatus),
                  'rounded-full'
                )}
              >
                {packageLocationAndSla?.dwellingTime}
              </Chip>
            </div>
          </ArrowTooltip>
        </>
      )}
    </div>
  )
}
