import { ContainerDetails, Containers } from '@/ViewContainers/models/Container'

import { ContainerDetailsResponse, ContainersResponse } from '../models/Container'

export function mapAllContainers(data: ContainersResponse): Containers {
  return {
    containers: data.containers.map((bin) => ({
      id: bin.id,
      floorAreaName: bin.floor_area_name,
      totalPackages: bin.total_packages,
      redPackages: bin.red_packages,
      orangePackages: bin.orange_packages,
      yellowPackages: bin.yellow_packages,
      whitePackages: bin.white_packages,
      dwellingStatus: bin.dwelling_status,
      binName: bin.bin_name,
      binAbbr: bin.bin_abbr,
      stations: bin.stations,
    })),
    containersColorCounts: data.containers_color_counts,
    meta: {
      pagination: {
        page: data.meta.pagination.page,
        next: data.meta.pagination.next,
        count: data.meta.pagination.count,
      },
    },
  }
}

export function mapContainerDetails(data: ContainerDetailsResponse): ContainerDetails {
  return {
    binName: data?.container.bin_name,
    id: data?.container.id,
    floorAreaName: data?.container.floor_area_name,
    totalPackages: data?.container.total_packages,
    redPackages: data?.container.red_packages,
    orangePackages: data?.container.orange_packages,
    yellowPackages: data?.container.yellow_packages,
    whitePackages: data?.container.white_packages,
    longestDwellingTime: data?.container.longest_dwelling_time,
    totalWeight: data?.container.total_weight,
    stationary: data?.container.stationary,
    stations: data?.container.stations,
    packageDetails: data?.container.package_details.map((pack) => ({
      slug: pack.status,
      name: pack.display_name,
    })),
    packageStatuses: data?.container.package_statuses.map((pack) => ({
      slug: pack.status,
      name: pack.display_name,
    })),
    packages: data?.container.packages?.map((pack) => ({
      id: pack.id,
      easyshipShipmentId: pack.easysip_shipment_id,
      evtn: pack.evtn,
      firstMileTrackingNumber: pack.package_first_mile_tracking_number,
      warehouseState: pack.package_warehouse_state,
      scanReceived: pack.scan_received,
      dwellingTime: pack.dwelling_time,
      dwellingStatus: pack.dwelling_status,
      courierUmbrellaName: pack.courier_umbrella_name,
    })),
  }
}
