import { ArrowTooltip, Chip } from 'easyship-components'
import React from 'react'

import { PackageCountColor } from '@/ViewContainers/models/Container'

import { getSLAColorStatus } from '../utils'

function getTooltipMessage(statusKey: PackageCountColor, count: number): React.ReactNode {
  const boldCount = <span className="font-bold">{count} packages</span>

  switch (statusKey) {
    case 'red':
      return <>{boldCount} breaching their max SLA time</>
    case 'orange':
      return <>{boldCount} breaching their min SLA time</>
    case 'yellow':
      return <>{boldCount} close to breaching their min SLA time</>
    case 'white':
    default:
      return <>{boldCount} currently on track for their SLA time</>
  }
}

interface PackageCountProps {
  statusKey: PackageCountColor
  count: number
}

export default function PackageCount({ statusKey, count }: PackageCountProps) {
  if (count === undefined || count === null) return null

  return (
    <ArrowTooltip
      label={<div className="text-center">{getTooltipMessage(statusKey, count)}</div>}
      maxWidth="narrow"
      placement="top"
    >
      <div>
        <Chip
          rounded
          className={`${getSLAColorStatus(statusKey)} ${
            statusKey === 'white' ? 'text-ink-500' : 'text-white'
          } min-w-[26px] rounded-full text-lg`}
        >
          {count}
        </Chip>
      </div>
    </ArrowTooltip>
  )
}
