import Chance from 'chance'

import { ContainerDetails, Containers } from '@/ViewContainers/models/Container'

const chance = new Chance()

export const allContainersStub = (): Containers => ({
  containers: [
    {
      id: chance.string(),
      binName: chance.string(),
      binAbbr: chance.string(),
      dwellingStatus: 'orange',
      floorAreaName: chance.string(),
      orangePackages: chance.natural(),
      redPackages: chance.natural(),
      yellowPackages: chance.natural(),
      whitePackages: chance.natural(),
      stations: ['Station 1', 'Station 2'],
      totalPackages: chance.natural(),
    },
    {
      id: chance.string(),
      binName: chance.string(),
      binAbbr: chance.string(),
      dwellingStatus: 'red',
      floorAreaName: chance.string(),
      orangePackages: chance.natural(),
      redPackages: chance.natural(),
      yellowPackages: chance.natural(),
      whitePackages: chance.natural(),
      stations: ['Station 1', 'Station 2'],
      totalPackages: chance.natural(),
    },
  ],
  containersColorCounts: {
    red: 10,
    orange: 10,
    yellow: 10,
    white: 10,
  },
  meta: {
    pagination: {
      page: 1,
      count: 10,
      next: 2,
    },
  },
})

export const containerDetailsStub = (): ContainerDetails => ({
  binName: chance.string(),
  floorAreaName: chance.string(),
  id: chance.string(),
  orangePackages: chance.natural(),
  redPackages: chance.natural(),
  yellowPackages: chance.natural(),
  whitePackages: chance.natural(),
  totalPackages: chance.natural(),
  longestDwellingTime: chance.string(),
  totalWeight: chance.string(),
  stationary: true,
  stations: ['INSP6'],
  packageDetails: [
    {
      name: chance.string(),
      slug: chance.string(),
    },
    {
      name: chance.string(),
      slug: chance.string(),
    },
  ],
  packageStatuses: [
    {
      name: chance.string(),
      slug: chance.string(),
    },
    {
      name: chance.string(),
      slug: chance.string(),
    },
  ],
  packages: [
    {
      dwellingStatus: 'orange',
      easyshipShipmentId: chance.string(),
      evtn: chance.string(),
      firstMileTrackingNumber: chance.string(),
      id: chance.string(),
      scanReceived: chance.string(),
      dwellingTime: chance.string(),
      warehouseState: chance.string(),
      courierUmbrellaName: 'DHL',
    },
    {
      dwellingStatus: 'orange',
      easyshipShipmentId: chance.string(),
      evtn: chance.string(),
      firstMileTrackingNumber: chance.string(),
      id: chance.string(),
      scanReceived: chance.string(),
      dwellingTime: chance.string(),
      warehouseState: chance.string(),
      courierUmbrellaName: 'Asendia',
    },
  ],
})
