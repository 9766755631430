import { Chip } from 'easyship-components'
import { Cross } from 'easyship-components/icons'
import { memo } from 'react'

import { ContainerFilterOptions } from '../type/ContainerFilterOptions'

interface FiltersProps {
  label: string
  filterOptions: ContainerFilterOptions[]
  selectedFilters?: string[]
  onFilterClick: (scanValue: string) => void
  isDesktop?: boolean
  showCounts?: boolean
}

const FilterComponent = ({
  onFilterClick,
  filterOptions,
  selectedFilters = [],
  label,
  isDesktop,
  showCounts = false
}: FiltersProps) => {
  function handleFilterClick(value: string) {
    onFilterClick(value)
  }

  if (filterOptions?.length === 0 || !filterOptions) {
    return null
  }

  return (
    <div className="mb-4 flex flex-col gap-3 text-sm text-ink-900 sm:w-full sm:flex-row md:w-11/12 lg:w-10/12">
      <div className="flex whitespace-nowrap">{label}:</div>
      <div className="flex flex-wrap gap-x-4 gap-y-3">
        {filterOptions?.map((type) => (
          <div
            data-testid={isDesktop ? `filter-${type.slug}` : ''}
            key={`${type.slug}-${type.name}`}
            onClick={() => handleFilterClick(type.slug)}
          >
            <Chip
              color={selectedFilters?.includes(type.slug) ? 'green' : 'ink'}
              className="cursor-pointer"
              key={`${type.slug}-${type.name}`}
              >
              {type.color && <div className={`mr-1 h-2 w-2 rounded-full ${type.color}`}></div>}
              {type.name}
              {showCounts && <span>&nbsp;({type.count})</span>}
              {selectedFilters?.includes(type.slug) && (
                <Cross height={12} width={12} className="ml-2 fill-white" />
              )}
            </Chip>
          </div>
        ))}
      </div>
    </div>
  )
}

const Filter = memo(FilterComponent)
export default Filter
