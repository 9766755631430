import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button } from 'easyship-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFeatureFlags } from '@/contexts/featureFlags'

import AppBar from '../../components/AppBar'
import Spacer from '../../components/Spacer'
import { useSession } from '../../contexts/session'

interface ButtonConfig {
  label: string
  path: string
  isEnabled?: boolean
}

interface Category {
  label: string
  buttons: ButtonConfig[]
  isEnabled?: boolean
}

export default function HomePage() {
  const navigate = useNavigate()
  const { logout } = useSession()
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  const { isFeatureEnabled } = useFeatureFlags()

  function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i
    return regex.test(navigator.userAgent)
  }

  function isBinVisibilityEnabled() {
    return !!isFeatureEnabled('EX2_1444_bin_visibility_M1')
  }

  async function handleLogout() {
    setIsLogoutLoading(true)
    try {
      await logout()
    } finally {
      setIsLogoutLoading(false)
    }
    navigate('/login')
  }

  const categories: Category[] = [
    {
      isEnabled: !isMobile(),
      label: 'Inbound',
      buttons: [
        { label: 'induction', path: '/induction' },
        { label: 'automation processing', path: '/label-processing' },
      ],
    },
    {
      isEnabled: !isMobile(),
      label: 'Outbound',
      buttons: [
        { label: 'courier sort', path: '/courier-sort' },
        { label: 'x-ray', path: '/x-ray' },
        { label: 'manifest', path: '/manifest' },
        { label: 'manifest V2', path: '/manifest-v2', isEnabled: false },
      ],
    },
    {
      label: 'Containers',
      buttons: [
        { label: 'view containers', path: '/view-containers', isEnabled: !isMobile() },
        { label: 'view locations & containers', path: '/view-items', isEnabled: isBinVisibilityEnabled() },
        {
          label: 'move items',
          path: '/move',
          isEnabled: isFeatureEnabled('EX2_903_move_module'),
        },
      ],
    },
    {
      isEnabled: !isMobile(),
      label: 'Screening',
      buttons: [{ label: 'pre-inspection', path: '/pre-inspection' }],
    },
  ]
  const renderButtons = (buttons: ButtonConfig[]) => (
    <Stack spacing={2}>
      {buttons.map(
        ({ isEnabled = true, label, path }, index) =>
          isEnabled && (
            <Button
              className="lg:h-[60px]"
              key={index}
              onClick={() => navigate(path)}
              color="default"
            >
              {label}
            </Button>
          ),
      )}
    </Stack>
  )

  return (
    <>
      <AppBar>
        <Spacer />
        <Button flat color="primary" onClick={handleLogout} loading={isLogoutLoading}>
          log out
        </Button>
      </AppBar>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        <Stack spacing={2} alignItems="center">
          <Button onClick={() => navigate('/scan')} color="primary" className="w-full">
            scan package
          </Button>

          <Grid width="100%" container spacing={2}>
            {categories.map(
              (category, index) =>
                (category.isEnabled ?? true) && (
                  <Grid className="!pl-0 sm:!pl-4" item xs={12} sm={6} md={3} key={index}>
                    <Typography textAlign="center" variant="h6" sx={{ mb: 1 }}>
                      {category.label}
                    </Typography>
                    {renderButtons(category.buttons)}
                  </Grid>
                ),
            )}
          </Grid>
        </Stack>
      </Container>
    </>
  )
}
