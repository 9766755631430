import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

interface FilterStates {
  packStatus: string[]
  packDetails: string[]
  dwellingStatus: string[]
}

export function useUpdateFilterUrlParams({
  packStatus,
  packDetails,
  dwellingStatus,
}: FilterStates) {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)

    if (packStatus.length) {
      params.set('selectedContainerFilters', packStatus.join(','))
    } else {
      params.delete('selectedContainerFilters')
    }

    if (packDetails.length) {
      params.set('packageDetails', packDetails.join(','))
    } else {
      params.delete('packageDetails')
    }

    if (dwellingStatus.length) {
      params.set('dwellingStatus', dwellingStatus.join(','))
    } else {
      params.delete('dwellingStatus')
    }

    const newUrl = window.location.pathname + '?' + params.toString()
    window.history.replaceState(null, '', newUrl)
  }, [packStatus, packDetails, dwellingStatus, searchParams])
}
