import { Accordion, AccordionDetails, AccordionSummary, Drawer } from '@mui/material'
import dayjs from 'dayjs'
import { Button } from 'easyship-components'
import {
  ChevronForward,
  CircleCheck,
  CirclePending,
  Cross,
  Exclaim,
  New,
  Print,
  PrintDisabled,
  Trash,
} from 'easyship-components/icons'

import { usePrintQueueMutation } from './hooks/usePrintQueueMutation'
import { useRetryPrintItemMutation } from './hooks/useRetryPrintItemMutation'
import PrintQueue, { PrintQueueItemStatus } from './models/PrintQueue'

interface PrintQueueDrawerProps {
  openDrawer: boolean
  onClose: () => void
  printQueue: PrintQueue[]
  openConfirmDeleteDialog: (id: number) => void
}

export default function PrintQueueDrawer({
  openDrawer,
  onClose,
  printQueue,
  openConfirmDeleteDialog,
}: PrintQueueDrawerProps) {
  const printQueueMutation = usePrintQueueMutation()
  const retryPrintItemMutation = useRetryPrintItemMutation()

  function getQueueItemIcon(status: PrintQueueItemStatus) {
    switch (status) {
      case 'failed':
        return <Exclaim className="fill-red-900" />
      case 'printing':
        return <CirclePending className="animate-spin fill-yellow-500" />
      case 'completed':
        return <CircleCheck className="fill-green-500" />
      case 'pending':
        return <CirclePending className="fill-sky-700" />
    }
  }

  function getPrintIcon(queue: PrintQueue) {
    if (queue.printQueueItems.every((item) => item.status === 'failed')) {
      return (
        <div className="relative">
          <PrintDisabled />
          <Exclaim className="absolute right-0 top-5 rounded-full bg-white fill-red-900" />
        </div>
      )
    }
    return (
      <div className="relative">
        <Print />
        {queue.status === 'printing' && (
          <CirclePending className=" absolute right-0 top-5 animate-spin fill-yellow-500" />
        )}
        {queue.status === 'completed' && (
          <CircleCheck className=" absolute right-0 top-5 rounded-full bg-white fill-green-500" />
        )}
        {queue.status === 'pending' && <New className="absolute right-0 top-5 fill-yellow-700" />}
      </div>
    )
  }

  function handleRemoveQueue(queueId: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    openConfirmDeleteDialog(queueId)
  }

  function handlePrintQueue(queueId: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    printQueueMutation.mutate(
      { queueId },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }

  function handleRetryPrintItem(queueId: number, queueItemId: number) {
    retryPrintItemMutation.mutate({ queueId, queueItemId })
  }

  function showTrashButtonBaseOnLabelStatus(queue: PrintQueue) {
    return (
      queue.status === 'completed' ||
      queue.status === 'pending' ||
      queue.printQueueItems.every((item) => item.status === 'failed')
    )
  }

  function getStatus(printQueue: PrintQueue) {
    if (printQueue.printQueueItems.every((item) => item.status === 'failed')) {
      return 'Failed'
    }
    switch (printQueue.status) {
      case 'pending':
        return 'Ready to print'
      case 'printing':
        return 'Printing'
      case 'completed':
        return 'Printed'
      default:
        return 'failed'
    }
  }

  return (
    <>
      <Drawer open={openDrawer} onClose={onClose} anchor="right">
        <div className="print-queue-drawer w-[380px]" role="print-queue-drawer">
          <div className="flex items-center justify-between border-b border-sky-500 p-4">
            <div className="text-xl text-ink-900">Print Queue</div>
            <Cross className="cursor-pointer fill-ink-500" onClick={onClose} />
          </div>
          <div>
            {printQueue?.map((queue) => (
              <Accordion key={queue.id} className="!m-0 !shadow-none ">
                <AccordionSummary
                  className="h-20 !border-b !border-solid !border-sky-300"
                  expandIcon={<ChevronForward />}
                >
                  <div className="flex w-full items-center justify-between  ">
                    <div className="flex items-center gap-1">
                      {getPrintIcon(queue)}
                      <div>
                        <div>{dayjs(queue.createdAt).format('DD MMM YYYY, h:mm A')}</div>
                        <div>{getStatus(queue)}</div>
                      </div>
                    </div>
                    {queue.status === 'pending' && (
                      <div>
                        <Button color="primary" onClick={(e) => handlePrintQueue(queue.id, e)}>
                          Print
                        </Button>
                      </div>
                    )}
                    <div className="flex items-center gap-1">
                      <div>{queue.printQueueItems.length} labels</div>
                      {showTrashButtonBaseOnLabelStatus(queue) && (
                        <Button
                          onClick={(e) => handleRemoveQueue(queue?.id, e)}
                          leftIcon={<Trash className="fill-ink-100" />}
                          flat
                          color="default"
                          role="trash-button"
                        />
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="!p-0">
                  {queue.printQueueItems.map((item) => (
                    <div
                      key={item.id}
                      className={`flex h-12 items-center justify-between gap-1 pl-12 pr-2 ${
                        item.status === 'failed' ? 'bg-red-100' : ''
                      }`}
                    >
                      <div className="flex items-center gap-1">
                        {getQueueItemIcon(item.status)}
                        <div>{item.title}</div>
                      </div>
                      {item.status === 'failed' && (
                        <Button
                          onClick={() => handleRetryPrintItem(queue.id, item.id)}
                          color="primary"
                          flat
                        >
                          retry
                        </Button>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  )
}
