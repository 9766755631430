import { Button } from 'easyship-components'

import NoContainersFoundSvg from '../../../assets/no_containers_find.svg'
import { useViewItems } from '../ViewItemsProvider'

export default function NoContainersFound() {
  const { handleResetFilter } = useViewItems()
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <img
        src={NoContainersFoundSvg}
        style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
      />
      <div className="text-2xl font-bold">No containers found</div>
      <div>No containers have been found matching all of your selected filter(s)</div>
      <Button onClick={handleResetFilter}>Reset filters</Button>
    </div>
  )
}
