import { Button } from 'easyship-components'
import { NavigateFunction } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Spacer from '@/components/Spacer'

interface ContainerDetailsAppBarProps {
    navigate: NavigateFunction
    originalParams: URLSearchParams
  }
export const ContainerDetailsAppBar = ({ navigate, originalParams }: ContainerDetailsAppBarProps) => {
    return (
      <AppBar>
        <Button
          color="primary"
          flat
          onClick={() => {
            navigate('/view-items?' + originalParams.toString())
          }}
        >
          back to view locations & containers
        </Button>
        <Spacer />
        <Button color="primary" flat onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
    )
  }