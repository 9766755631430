import { Button, Input } from 'easyship-components'

import ScanInput from '@/components/ScanInput'
import Switch from '@/components/Switch'

import { useViewItems } from '../ViewItemsProvider'





export default function Scan() {
  const {
    toggleValue,
    searchValue,
    handleToggleSearchScan,
    handleSetSearchValue,
    handleSearch,
    handleResetSearch,
    handleScanBarcode
  } = useViewItems()

  return (
    <div className="block min-h-[72px] rounded-md bg-white p-4 shadow sm:flex sm:items-center">
      <div className="flex">
        <Switch
          leftLabel="Scan"
          rightLabel="Search"
          checked={toggleValue === 'SEARCH'}
          onChange={(_, checked) => handleToggleSearchScan(checked)}
        />
      </div>
      <div className="flex-auto sm:ml-3">
        {toggleValue === 'SCAN' && (
          <div>
            <ScanInput
              onChange={handleScanBarcode}
              placeholder="Scan to see container details"
              autoFocus
            />
          </div>
        )}
        {toggleValue === 'SEARCH' && (
          <div className="flex items-center">
            <Input
              aria-label="Search containers"
              value={searchValue}
              onChange={(e) => handleSetSearchValue(e.target.value)}
              placeholder="Search container name (accepts partial names) to see container details"
              autoFocus={toggleValue === 'SEARCH'}
            />
            <Button onClick={() => handleSearch(searchValue)} className="mx-3" color="primary">
              Search
            </Button>
            <Button onClick={handleResetSearch}>Reset</Button>
          </div>
        )}
      </div>
    </div>
  )
}
