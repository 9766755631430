import { DwellingStatus } from "@/ViewContainers/models/Container"

export const gePackageTooltipMessage = (status: DwellingStatus, hour: string): React.ReactNode => {
    switch (status) {
      case 'red':
        return (
          <div>
            Package is breaching its max SLA time of{' '}
            <span className="font-bold">{hour}</span>
          </div>
        )
      case 'orange':
        return (
          <div>
            Package is breaching its min SLA time of{' '}
            <span className="font-bold">{hour}</span>
          </div>
        )
      case 'yellow':
        return (
          <div>
            Package is close to breaching its min SLA time of{' '}
            <span className="font-bold">{hour}</span>
          </div>
        )
      case 'white':
        return <div>Package is currently on track for its SLA time</div>
      default:
        return <div>Package is currently on track for its SLA time</div>
    }
  }