import Chance from 'chance'

import Package from '../../../core/models/Package'

const chance = new Chance()

export const packageStub = (): Package => ({
  id: '944dadb2-a3ea-4c69-bae0-2045f634e3b0',
  ebayPackageId: 'LBHDHXA-EEUS000116885FR',
  scanReceived: '',
  canBeMarkedReceivedDamaged: false,
  easyshipShipmentId: 'EEUS000116885FR',
  lastStationId: 1,
  firstMileTrackingNumber: '',
  lastMileTrackingNumber: 'EEUS000116885FR0',
  combinedShipment: false,
  items: [
    {
      ebayItemId: '128392174102983',
      description: 'Android machine',
      url: 'https://ebay.com/192801297413',
      quantity: 1,
      sku: '364065960243',
    },
  ],
  warehouseState: { value: 'ready_for_xray', displayName: 'Ready for X-Ray' },
  lastMileCourierName: 'DHL eCommerce - Parcel Priority',
  flaggedFor: [],
  screeningFlags: [],
  inspections: [
    {
      id: '2',
      category: '',
      source: 'xray',
      status: 'requested',
    },
  ],
  batteryInspectionOutcome: {
    result: 'no_batteries',
    shippingDocumentInstructions: [],
    stickers: [],
    unshippableReasons: [],
    classifications: [],
  },
  labelState: 'not_created',
  evtn: 'bea423',
  senderName: 'Cory Will',
  receiverAddress: {
    fullName: 'Cuc Thompson',
    addressLine1: '9426 Celine Viaduct',
    addressLine2: 'Apt. 310',
    city: 'Emmerichton',
    state: 'New Jersey',
    postalCode: '43823-7778',
    countryCode: 'JO',
    country: 'CA',
  },
  bin: null,
  weight: {
    value: 10.33,
    unit: 'lb',
  },
  dimensions: {
    length: 10,
    width: 8.2,
    height: 6.4,
    unit: 'in',
  },
  courierReturn: null,
  repack: null,
  multipack: false,
  packageLocationAndSla: {
    floorAreaId: '1',
    floorAreaName: 'HP7',
    binId: '2342',
    binName: 'STN-1',
    dwellingStatus: 'red',
    dwellingTime: '10 hours',
    description: 'Package is breaching its max SLA time of 10 hours',
  },
  packageTags: [],
  processedCourierReturnCount: 0,
  lastProcessedCourierReturn: null,
})
