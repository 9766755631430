import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

import { ToggleValues } from './ViewItemsProvider'

export default function useScanContainerQuery(barcode: string, toggleValue: ToggleValues) {
  const { binsGateway } = useGateways()

  const query = useQuery({
    queryKey: ['scanContainer', barcode],
    queryFn: () => binsGateway.getScanContainer(barcode),
    enabled: !!barcode && toggleValue === 'SCAN',
    cacheTime: 0,
    staleTime: 0,
  })

  return query
}
