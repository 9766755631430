import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { lazy, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useGateways } from '@/contexts/gateways'
import { useOpenModal } from '@/hooks/useOpenModal.ts'

import { NavigationStateParams } from './ContainerDetailsPage/ContainerDetailsPage'

const ContainerNotFoundConfirmationDialog = lazy(() => import('./components/ContainerNotFoundConfirmationDialog'));

export default function ActionsButton() {
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams
  const { binsGateway } = useGateways()

  const openModalHandler = useOpenModal();


  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const isActionsMenuOpen = !!actionsMenuAnchorEl

  const handlePrintBarcodeClick = async () => {
    setActionsMenuAnchorEl(null)
    try {
      await binsGateway.printBarcode(navigationState?.container?.id as string)
    } catch (error) {
      console.error('Error printing barcode:', error)
    }
  }

  const handelMoveToNotFoundClick = () => {
    setActionsMenuAnchorEl(null)
    openModalHandler(ContainerNotFoundConfirmationDialog, {
      binId: navigationState?.container?.id as string,
      containerName: navigationState?.container?.binName as string,
    })
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => setActionsMenuAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
      >
        actions
      </Button>
      <Menu
        open={isActionsMenuOpen}
        anchorEl={actionsMenuAnchorEl}
        onClose={() => setActionsMenuAnchorEl(null)}
      >
        <MenuItem onClick={handlePrintBarcodeClick}>Print Barcode</MenuItem>
        <MenuItem onClick={handelMoveToNotFoundClick}>Move to &quot;Not Found&quot;</MenuItem>
      </Menu>
    </>
  )
}
