import Container from '@mui/material/Container'
import { Button, ToggleButton } from 'easyship-components'
import { Plus, PrintQueue as PrintQueueIcon } from 'easyship-components/icons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Spacer from '@/components/Spacer'
import ConfirmDeletePrintQueueDialog from '@/ViewContainers/ConfirmDeletePrintQueueDialog'
import useGetPrintQueueQuery from '@/ViewContainers/hooks/useGetPrintQueueQuery'
import PrintQueue from '@/ViewContainers/models/PrintQueue'
import NewContainerDialog from '@/ViewContainers/NewContainerDialog'
import PrintQueueDrawer from '@/ViewContainers/PrintQueueDrawer'

import AllContainersList from './AllContainersList'
import Filter from './components/Filter'
import Scan from './components/Scan'
import { ViewItemsProvider, useViewItems } from './ViewItemsProvider'

type ToggleValues = 'location' | 'container'

function ViewItemsContent() {
  const { data: printQueue } = useGetPrintQueueQuery()
  const [isNewBinDialogOpen, setIsNewBinDialogOpen] = useState(false)
  const [isPrintQueueOpen, setIsPrintQueueOpen] = useState(false)
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
  const [deleteQueue, setDeleteQueue] = useState<PrintQueue>()
  const [activeView, setActiveView] = useState<ToggleValues>('container')
  const {
    containerFilterOptions,
    selectedContainerFilters,
    dwellingStatus,
    handleFilterContainerType,
    handleFilterDwellingStatus,
    containerCounts,
    slaStatusWithCounts
  } = useViewItems()

  function getPrintQueueById(id: number) {
    setIsPrintQueueOpen(false)
    setIsConfirmDeleteDialogOpen(true)
    setDeleteQueue(printQueue?.find((queue) => queue.id === id))
  }

  return (
    <div className="mt-8 flex h-full flex-col">
      <div className="mb-8">
        <div className="mb-8 flex items-center justify-between">
          <div className="text-1_5xl font-bold">View Locations & Containers</div>
          <div className="hidden items-center gap-4 sm:flex">
            <Button onClick={() => setIsNewBinDialogOpen(true)} leftIcon={<Plus />}>
              Create new containers
            </Button>
            <Button onClick={() => setIsPrintQueueOpen(true)} leftIcon={<PrintQueueIcon />}>
              print queue
            </Button>
          </div>
        </div>
        <Scan />
      </div>
      {/* show for release 2 */}
      {/* <div className="block items-center justify-between sm:flex">
        <ToggleButton
          readOnly
          onChange={(value: ToggleValues) => setActiveView(value)}
          monochrome
          value={activeView}
          options={[
            {
              label: 'All Locations',
              value: 'location',
            },
            {
              label: 'All Containers',
              value: 'container',
            },
          ]}
        />
        <Button className="mt-4 sm:mt-0">View &quot;not found&quot;</Button>
      </div> */}
      <div className="my-8">
        <div className="pb-4 text-lg text-ink-900">
          All Containers <span className="font-bold">({containerCounts})</span>
        </div>
        <Filter
          filterOptions={containerFilterOptions}
          selectedFilters={selectedContainerFilters}
          label="Container type"
          onFilterClick={handleFilterContainerType}
        />
        <Filter
          filterOptions={slaStatusWithCounts}
          selectedFilters={dwellingStatus}
          label="SLA status"
          onFilterClick={handleFilterDwellingStatus}
          showCounts={true}
        />
      </div>
      {activeView === 'container' && <AllContainersList />}
      <NewContainerDialog open={isNewBinDialogOpen} onClose={() => setIsNewBinDialogOpen(false)} />
      <PrintQueueDrawer
        printQueue={printQueue as PrintQueue[]}
        openDrawer={isPrintQueueOpen}
        onClose={() => setIsPrintQueueOpen(false)}
        openConfirmDeleteDialog={getPrintQueueById}
      />
      <ConfirmDeletePrintQueueDialog
        open={isConfirmDeleteDialogOpen}
        printQueue={deleteQueue}
        onClose={() => setIsConfirmDeleteDialogOpen(false)}
      />
    </div>
  )
}

export default function ViewItemsPage() {
  const navigate = useNavigate()

  return (
    <div className="h-full">
      <AppBar title="View Locations & Containers">
        <Spacer />
        <Button color="primary" flat onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <ViewItemsProvider>
        <Container sx={{ my: 3 }}>
          <ViewItemsContent />
        </Container>
      </ViewItemsProvider>
    </div>
  )
}
