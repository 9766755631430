import { ContainersColorCounts } from '@/ViewContainers/models/Container'

export interface ContainersResponse {
  containers: Container[]
  containers_color_counts: ContainersColorCounts
  meta: Meta
}

interface Container {
  id: string
  floor_area_name: string
  total_packages: number
  red_packages: number
  orange_packages: number
  yellow_packages: number
  white_packages: number
  dwelling_status: DwellingStatus
  bin_name: string
  bin_abbr?: string
  stations: string[]
}

interface Meta {
  pagination: {
    page: number
    next: number
    count: number
  }
}

export interface ContainerDetailsResponse {
  container: ContainerDetails
}

interface ContainerDetails {
  id: string
  bin_name: string
  floor_area_name: string
  total_packages: number
  red_packages: number
  orange_packages: number
  yellow_packages: number
  white_packages: number
  longest_dwelling_time: string
  total_weight: string
  packages: Package[]
  package_statuses: PackageStatus[]
  package_details: PackageDetail[]
  stationary: boolean
  stations: string[]
}

interface Package {
  id: string
  easysip_shipment_id: string
  evtn: string
  package_first_mile_tracking_number: string
  package_warehouse_state: string
  scan_received: string
  dwelling_time: string
  dwelling_status: DwellingStatus
  courier_umbrella_name: CourierUmbrella | null
}

export enum CourierUmbrella {
  Asendia = 'Asendia',
  PitneyBowes = 'Pitney Bowes',
  DHLeCommerce = 'DHL eCommerce',
  DHL = 'DHL',
  EPostGlobal = 'ePostGlobal',
  SFExpress = 'SFExpress',
  SkyPostal = 'SkyPostal',
}

interface PackageStatus {
  status: string
  display_name: string
}
interface PackageDetail {
  status: string
  display_name: string
}

export type DwellingStatus = 'red' | 'orange' | 'yellow' | 'white'
