import { useCallback, useEffect, useRef } from 'react'

import { ComponentDataType, ComponentType, openModal } from '@/utils/modal'

import { OpenModalType } from './type'

type OpenModalReturnType = ReturnType<typeof openModal>

export const useOpenModal = () => {
  const refOpenModalResult = useRef<OpenModalReturnType>()

  const openModalHandler = useCallback((component: ComponentType, data?: ComponentDataType) => {
    refOpenModalResult.current = openModal(component, data)
    return refOpenModalResult.current
  }, [])

  useEffect(() => {
    return () => {
      refOpenModalResult.current?.close()
    }
  }, [])

  return openModalHandler as OpenModalType
}
