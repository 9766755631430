import { Pulse } from 'easyship-components'
import { memo, Suspense, useEffect, useLayoutEffect, useState, useSyncExternalStore } from 'react'

import store, { ModalsType, removeClosedModals } from '@/utils/modal'

function ModalToShow({ modal }: { modal: ModalsType }) {
  const { component: ModalComponent, data = {}, guid, close, isClosed } = modal

  const [openState, setOpenState] = useState(false)

  useEffect(() => {
    setOpenState(!isClosed)
  }, [isClosed])

  const closeModal = () => {
    setOpenState(false)
    close()
  }

  return (
    <ModalComponent key={guid} {...(data as object)} handleClose={closeModal} isOpen={openState} />
  )
}

const ModalContainer = memo(() => {
  const modals = useSyncExternalStore(store.subscribe, () => store.getModals())

  useLayoutEffect(() => {
    removeClosedModals()
  }, [])

  return (
    <>
      {modals.map((modal: ModalsType) => (
        <Suspense fallback={<Pulse show className="z-[9999]" />} key={modal.guid}>
          <ModalToShow modal={modal} />
        </Suspense>
      ))}
    </>
  )
})

ModalContainer.displayName = 'ModalContainer'

export default ModalContainer
